<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <div class="head-box">
                    <div class="table-operator">
                        <a-button icon="plus" type="primary" @click="onAdd" v-show="isShowAdd">新建活动弹窗</a-button>
                    </div>
                </div>
                <div class="contant-box">
                    <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.ad_no"  :loading="tableLoading" :pagination="false">
                        <span slot="number" slot-scope="number, record, index">
                            <span>{{ index + 1 }}</span>
                        </span>
                        <img v-viewer class="avatar" slot="images" slot-scope="images, record" :src="record.images" alt="头像"/>
                        <span slot="putaway_flag" slot-scope="putaway_flag, record">
                            <a-badge v-show="record.putaway_flag == 1" status="error" text="下架"/>
                            <a-badge v-show="record.putaway_flag == 2" status="success" text="上架"/>
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <template>
                                <a class="margin_right10" v-show="record.putaway_flag == 1 && isShowAway" @click="releaseStatusConfirm(record)">上架</a>
                                <a class="margin_right10" v-show="record.putaway_flag == 2 && isShowAway" @click="releaseStatusConfirm(record)">下架</a>
                                <a v-show="isShowUpd" class="btn-edit" @click="onEdit(record)">编辑</a>
                                <a v-show="isShowDel" class="btn-delete" @click="onDel(record)">删除</a>
                            </template>
                        </span>
                    </a-table>
                </div>
            </div>
            <!-- 分页功能 -->
            <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
        </a-spin>
        <router-view/>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {Modal, message} from "ant-design-vue";
import {codeFn} from "@/utils/tools";
import {GetHomeAdList,DelHomeAd,PutAwayAd} from "@/request/api/indexManage";
export default {
    components: {MyPagination},
    created() {
        if (codeFn("/adminv2/home/addHomeAd")) this.isShowAdd = true;
        if (codeFn("/adminv2/home/getHomeAdInfo")) this.isShowUpd = true;
        if (codeFn("/adminv2/home/delHomeAd")) this.isShowDel = true;
        if (codeFn("/adminv2/home/putAwayAd")) this.isShowAway = true;
    },
    data() {
        return {
            spinning: false, // 全局loading
            columns: [
                {
                    title: "序号",
                    dataIndex: "number",
                    key: "number",
                    scopedSlots: {customRender: "number"},
                },
                {
                    title: "弹窗图片图片",
                    dataIndex: "images",
                    key: "images",
                    scopedSlots: {customRender: "images"},
                },
                {
                    title: "名称",
                    dataIndex: "name",
                    key: "name",
                },
                {
                    title: "状态",
                    dataIndex: "putaway_flag",
                    key: "putaway_flag",
                    scopedSlots: {customRender: "putaway_flag"},
                },
                {
                    title: "生效时间",
                    dataIndex: "start_time",
                    key: "start_time",
                },
                {
                    title: "失效时间",
                    dataIndex: "end_time",
                    key: "end_time",
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    width: "180px",
                    scopedSlots: {customRender: "action"},
                },
            ],
            tableList: [],
            tableLoading: false,
            confirmLoading: false,
            isShowAdd: false, // 新增是否隐藏
            isShowUpd: false, // 修改是否隐藏
            isShowDel: false, // 删除是否隐藏
            isShowAway: false, // 上下架是否隐藏
            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0// 列表的总条数
        };
    },
    mounted() {
        this.getTagList();
    },
    methods: {

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList();
        },

        // 列表数据
        getTagList() {
            this.tableLoading = true;
            GetHomeAdList({
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.list;
                    this.count = res.data.count;
                }
                this.tableLoading = false;
            });
        },

        // 发布/取消发布弹窗
        releaseStatusConfirm(record) {
            let self = this
            const text = record.putaway_flag === 1 ? '上架' : '下架';
            Modal.confirm({
                title: "提示",
                content: `确认${text}吗?`,
                okText: "确认",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    PutAwayAd({
                        id: record.ad_no
                    }).then(({code, msg}) => {
                        if (code === 200) {
                            self.getTagList();
                        } else {
                            message.error(msg || "操作失败，请稍后重试");
                        }
                    });
                }
            });
        },

        // 添加
        onAdd() {
            this.$router.push("/indexManage/ExercisePop/ExercisePopEdit/0");
        },

        // 编辑
        onEdit(record) {
            this.$router.push(`/indexManage/ExercisePop/ExercisePopEdit/${record.ad_no}`);
        },

        // 删除
        onDel(record) {
            let that = this;
            Modal.confirm({
                title: "提示",
                content: "是否确认删除此活动弹窗吗？",
                okText: "确认",
                okType: "danger",
                cancelText: "取消",
                onOk() {
                    DelHomeAd({id: record.ad_no}).then(({code, msg}) => {
                        if (code === 200) {
                            message.success("删除成功", 3);
                            that.getTagList();
                        } else {
                            message.error(msg || "操作失败，请稍后重试");
                        }
                    })
                }
            });
        },
        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#course");
        },
    }
};
</script>

<style lang="less" scoped>
#course_category {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.head-box {
    margin-bottom: 30px;
}

.avatar {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.btn-edit {
    margin-right: 10px;
}
</style>
